import { useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { usePostSetPrimaryNFD } from 'api/hooks/usePostSetPrimaryNFD'
import { customAxios } from 'api/mutator/custom-axios'
import useErrorToast from 'hooks/useErrorToast'
import { getCacheBypassParam } from './SetPrimaryNFD.utils'
import type { NfdGetNFDsForAddressesV2200, NfdRecord, NfdRecordCollection } from 'api/api-client'

export default function usePrimaryNfd(address = '') {
  const getReverseLookup = async (address: string) => {
    const cacheBypass = getCacheBypassParam()

    const params = {
      address: [address],
      limit: 20,
      view: 'thumbnail',
      cacheBypass
    }

    const response = await customAxios<NfdGetNFDsForAddressesV2200>(
      { url: `/nfd/v2/address`, method: 'get', params },
      {
        validateStatus: (status: number) => (status >= 200 && status < 300) || status === 404
      }
    )

    return response.data[address] || null
  }

  const revLookupQuery = useQuery<NfdRecordCollection, AxiosError>(
    ['revLookup', { address }],
    () => getReverseLookup(address),
    {
      enabled: address !== ''
    }
  )

  const { mutateAsync: setPrimaryNfd } = usePostSetPrimaryNFD({
    onSuccess: (data, params) => {
      queryClient.invalidateQueries({ queryKey: ['name', params.name] })
    },
    toasts: {
      loading: 'Setting primary NFD...',
      success: 'Primary NFD set!'
    }
  })

  const handleError = useErrorToast()
  const queryClient = useQueryClient()

  // update UserThumbnail query data in client cache for this address
  const updateUserThumbnail = (primaryNfd: NfdRecord) => {
    const queryKey = [
      'nfd-lookup',
      {
        address: [address],
        view: 'thumbnail'
      }
    ]
    queryClient.setQueryData(queryKey, [primaryNfd])
  }

  // update SetPrimaryNFD query data in client cache
  const updateSetPrimaryNFD = (primaryNfd: NfdRecord, queryData: NfdRecordCollection) => {
    const queryKey = ['revLookup', { address }]
    queryClient.setQueryData(queryKey, [
      primaryNfd,
      ...queryData.filter((nfd) => nfd.name !== primaryNfd.name)
    ])
  }

  const handleSetPrimaryNfd = async (name: string) => {
    try {
      if (address === '') {
        throw new Error('No active wallet')
      }

      await setPrimaryNfd({
        name,
        body: {
          address,
          sender: address
        }
      })

      const newPrimaryNfd = revLookupQuery?.data?.find((nfd) => nfd.name === name)

      if (newPrimaryNfd) {
        updateUserThumbnail(newPrimaryNfd)

        if (Array.isArray(revLookupQuery.data)) {
          updateSetPrimaryNFD(newPrimaryNfd, revLookupQuery.data)
        }
      }

      setTimeout(() => {
        revLookupQuery.refetch()
      }, 1500)
    } catch (err) {
      handleError(err)
    }
  }

  return {
    revLookupQuery,
    handleSetPrimaryNfd
  }
}

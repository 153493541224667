import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { HiChevronDown, HiCheck } from 'react-icons/hi'
import LoadingDots from 'components/LoadingDots'
import UserThumbnail from 'components/UserThumbnail'
import { classNames } from 'helpers/utilities'
import type { UseQueryResult } from '@tanstack/react-query'
import type { AxiosError } from 'axios'
import type { NfdRecordCollection } from 'api/api-client'

interface SetPrimaryNFDProps {
  address: string
  revLookupQuery: UseQueryResult<NfdRecordCollection, AxiosError>
  onSetPrimaryNfd: (name: string) => Promise<void>
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  className?: string
}

export default function SetPrimaryNFD({
  address,
  revLookupQuery,
  onSetPrimaryNfd,
  size = 'md',
  className = ''
}: SetPrimaryNFDProps) {
  const { data, isInitialLoading: isLoading, error } = revLookupQuery

  const SIZES = {
    xs: 'px-2.5 py-1.5 text-xs rounded',
    sm: 'px-3.5 py-1.5 text-sm rounded-md',
    md: 'px-3 py-2 text-sm rounded-md',
    lg: 'px-4 py-2 text-base rounded-md',
    xl: 'px-6 py-3 text-base rounded-md'
  }

  const ICON_SIZES = {
    xs: '',
    sm: '-mr-0.5 ml-2 h-4 w-4',
    md: '-mr-1 ml-2 h-5 w-5',
    lg: '-mr-1 ml-3 h-5 w-5',
    xl: '-mr-1 ml-3 h-5 w-5'
  }

  if (isLoading) {
    return <LoadingDots className="m-3" />
  }

  if (!data || data.length === 0 || error) {
    return (
      <UserThumbnail
        address={address}
        forceAddressFallback={!error}
        className="block p-1 rounded-full pr-4 bg-white dark:bg-gray-900"
        fallbackClassName="block -ml-1 p-1.5 rounded-full pr-4 text-gray-500 bg-white dark:bg-gray-900"
      />
    )
  }

  if (data.length === 1) {
    return (
      <UserThumbnail
        nfd={data[0]}
        className="block p-1 rounded-full pr-4 bg-white dark:bg-gray-900"
        fallbackClassName="text-gray-500"
      />
    )
  }

  return (
    <Menu key={data[0].name} as="div" className={classNames('relative z-30', className)}>
      <div>
        <Menu.Button
          className={classNames(
            'flex w-full items-center justify-center shadow-sm font-medium group border border-gray-300 text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 disabled:opacity-50 disabled:bg-white disabled:text-gray-700 dark:bg-gray-750 dark:border-transparent dark:text-gray-300 dark:hover:bg-gray-700 dark:focus:ring-offset-gray-900 dark:focus:ring-gray-100 dark:disabled:bg-gray-750',
            SIZES[size]
          )}
        >
          Set Primary NFD
          <HiChevronDown
            className={classNames(
              'hidden sm:inline-flex',
              ICON_SIZES[size],
              'text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-400'
            )}
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-center absolute z-20 mt-2 w-full sm:w-80 rounded-md shadow-lg max-h-80 overflow-y-auto bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none dark:bg-gray-850 dark:divide-gray-750 dark:ring-opacity-20">
          <div className="py-1 dark:bg-gray-900/50">
            {data.map((nfd, i) => (
              <Menu.Item key={nfd.name}>
                {({ active }) => (
                  <button
                    type="button"
                    className={classNames(
                      active ? 'bg-gray-100 dark:bg-gray-900' : '',
                      'flex items-center justify-between px-4 py-2 text-sm w-full text-left'
                    )}
                    onClick={() => onSetPrimaryNfd(nfd.name)}
                  >
                    <UserThumbnail nfd={nfd} className="min-w-0" link={false} padded />
                    {i === 0 && <HiCheck className="h-5 w-5 text-brand-500" aria-hidden="true" />}
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
